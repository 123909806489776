<template>
  <h1>🖐 Hello world 🖐</h1>

  <br />
  <input v-model="inputSaludo" placeholder="saludo" @keyup.enter="add" />
  <button @click="add" text="send!">Enviar!</button>
  <br />

  <h3 v-for="(saludo, index) in saludos" :key="saludo.id">
    <span>
      {{ saludo.texto }}
    </span>
    <button :key="saludo.id" @click="del($event, index, saludo.id)">-</button>
  </h3>

  <br />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      saludos: [],
      inputSaludo: "",
    };
  },

  mounted() {
    this.load();
    this.handleIcon(1);
  },

  methods: {
    async load() {
      const response = await fetch(
        "https://jylvbwvzkmdlevghmmqi.supabase.co/rest/v1/saludos",
        {
          headers: {
            "Content-Type": "application/json",
            apikey:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyODU1MTY2MywiZXhwIjoxOTQ0MTI3NjYzfQ.4lWH10LGp1bFWCP_1rYRK1b66T8mshPJ9led6H4wGVo",
          },
        }
      );

      if (response.ok) this.saludos = await response.json();
    },

    async add() {
      const response = await fetch(
        "https://jylvbwvzkmdlevghmmqi.supabase.co/rest/v1/saludos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            apikey:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyODU1MTY2MywiZXhwIjoxOTQ0MTI3NjYzfQ.4lWH10LGp1bFWCP_1rYRK1b66T8mshPJ9led6H4wGVo",
            Prefer: "return=representation",
          },
          body: JSON.stringify({ texto: this.inputSaludo }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // data es un array!

        this.inputSaludo = "";

        this.saludos = this.saludos.concat(data);
      }
    },

    async del(e, index, id) {
      //if (!confirm("Deseas borrar este elemento?")) return;

      const response = await fetch(
        `https://jylvbwvzkmdlevghmmqi.supabase.co/rest/v1/saludos?id=eq.${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            apikey:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyODU1MTY2MywiZXhwIjoxOTQ0MTI3NjYzfQ.4lWH10LGp1bFWCP_1rYRK1b66T8mshPJ9led6H4wGVo",
          },
        }
      );

      if (response.ok) {
        this.saludos.splice(index, 1);
      }
    },

    handleIcon(num) {
      console.log(num);
      const favicon = document.getElementById("favicon");
      // if (num > 9) favicon.href = `+9.png`;
      //  else favicon.href = `${num}.png`;
      if (num > 0) favicon.href = "favicon-notify-no-padding.png";
      else favicon.href = "favicon-no-padding.png";

      const title = document.getElementById("title");
      if (num > 0) title.innerText = `Hello • ${num}`;
      else title.innerText = "Hello • 👋";
    },
  },

  watch: {
    saludos: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.handleIcon(val.length);
      },
    },
  },

  // computed: {
  //   items: function () {
  //     this.handleIcon(this.saludos.length);
  //     return this.saludos.length;
  //   },
  // },
};
</script>

<style lang="scss">
@import "@/styles.scss";
/* 
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: #2c3e50;
}
*/
input,
button {
  padding: 10px;
  margin-left: 3px;
}

h3 {
  display: flex;
  padding: 10px 4px 0 6px;
}
h3 span {
  vertical-align: middle;
}
h3 button {
  padding: 1px 8px 3px;
  margin: 0 3px;
  vertical-align: middle;
}
</style>
